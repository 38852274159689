<template>
    <div id="app">
        <header class="app-header">
            <div class="header-content">
                <img 
                    src="@/assets/robot globar header logo_1920x220.svg" 
                    alt="Robot Globar Logo" 
                    class="header-logo"
                >
            </div>
        </header>

        <main class="main-content">
            <router-view /> <!-- Router view handles the current route -->
        </main>

        <footer>
            <div class="logos">
                <img src="@/assets/globant_logo.png" alt="Globant Logo" class="logo" />
                <img src="@/assets/inorbit_logo.png" alt="InOrBit Logo" class="logo" />
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
/* Import the chosen font */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

html,
body {
    height: 100%;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    /* Ensure the app takes at least full viewport height */
    width: 100%;
    padding: 0;
    box-sizing: border-box;
}

header {
    text-align: center;
    width: 100%;
    background-color: #000000;
    padding: 10px 0;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px; /* Add a small gap between elements */
}

.url {
    color: #ffffff;
    font-size: 0.8rem;
    margin-bottom: 5px;
}

.glow-title {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 3rem;
    color: #ffffff;
    text-shadow: 0 0 2px #b4ff39, 0 0 1px #b4ff39;
    margin: 0; /* Remove margin to bring elements closer */
}

.powered-by {
    color: #ffffff;
    font-size: 1.2rem;
    margin-top: 0px;
}

/* Main Content Container */
.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 40px; /* Reduced top padding from 40px to 20px */
    box-sizing: border-box;
    margin-top: -20px; /* Added negative margin to bring content closer to header */
}

/* Font Imports */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Global Heading Styles */
h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    color: #333333;
    text-align: left;
}

/* Footer Section Styling */
footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    background-color: transparent;
    width: 100%;
}

/* Logos Styling */
.logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
}

.logo {
    width: auto;
    height: 40px;
    object-fit: fill;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 600px) {
    h1 {
        font-size: 2rem;
    }

    .logos {
        gap: 40px;
    }

    .logo {
        height: 30px;
        max-width: 150px;
    }

    .main-content {
        padding: 10px;
        max-width: 100%;
    }
}

.app-header {
    background-color: #000000;
    width: 100%;
    overflow: hidden;
}

.header-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.header-logo {
    height: 70px;
    width: auto;
    max-width: none;
    object-fit: contain;
    object-position: center;
}

.header-content::before,
.header-content::after {
    content: none;
}

/* Laptops and large tablets */
@media (max-width: 1366px) {
    .header-logo {
        height: 80px;
    }
}

/* Tablets */
@media (max-width: 1024px) {
    .header-logo {
        height: 80px;
    }
}

/* Phones (portrait mode) */
@media (max-width: 768px) {
    .app-header {
        padding: 15px 0;
    }

    .header-logo {
        width: 150%;
        height: 80px;
    }
}

/* Small phones */
@media (max-width: 480px) {
    .header-logo {
        width: 180%;
        height: 70px;
    }
}

/* Very small phones */
@media (max-width: 320px) {
    .app-header {
        padding: 20px 0;
    }

    .header-logo {
        height: 100px;
    }
}
</style>
