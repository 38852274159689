<template>
    <div class="menu-item">
        <p class="item-name">{{ item.name }}</p>
        <img :src="item.image" :alt="item.name" class="menu-image" />
        <button @click="orderDrink" class="order-button">Place Order</button>
    </div>
</template>

<script>
export default {
    name: "MenuItem",
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        orderDrink() {
            // Emit an event to the parent component when a drink is ordered
            this.$emit('order', this.item);
        }
    }
};
</script>

<style scoped>
.menu-item {
    width: 140px;
    height: 220px; /* Adjusted height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 2px solid #C0D639; /* Updated border color */
    border-radius: 10px;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added shadow */
}

.item-name {
    font-size: 1rem;
    font-weight: bold; /* Bold text */
    color: #333333;
    margin: 0;
}

.menu-image {
    width: 100px;
    height: 120px;
    object-fit: contain;
    margin: 0;
}

.order-button {
    width: 77%; /* Make the button take up the full width */
    padding: 10px 0; /* Adjust padding for height */
    background-color: #C0D639; /* Updated button color */
    color: #333333; /* Updated text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem; /* Increased font size */
}

.order-button:hover {
    background-color: #b3d16d; /* Slightly darker on hover */
}
</style>