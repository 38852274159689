<template>
  <div class="on-the-way-orders">
    <div v-if="onTheWayDeliveries.length === 0" class="no-orders">
      No orders currently on the way.
    </div>
    <div v-else>
      <div v-for="(delivery, index) in onTheWayDeliveries" :key="delivery.id" class="delivery">
        <h4>Delivery {{ index + 1 }} ({{ delivery.id }})</h4>
        <table class="order-table">
          <thead>
            <tr>
              <th>Order Placed At</th>
              <th>Customer</th>
              <th>Items</th>
              <th>Destination</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="orderRef in delivery.orderReferences" :key="orderRef.orderId" class="order-item">
              <td>{{ formatOrderTime(getOrderById(orderRef.orderId).placedAt) }}</td>
              <td>{{ getOrderById(orderRef.orderId).userName }}</td>
              <td>{{ getOrderById(orderRef.orderId).items.join(', ') }}</td>
              <td>{{ orderRef.destination }}</td>
            </tr>
          </tbody>
        </table>
        <div class="delivery-actions">
          <button v-if="showPauseResumeButtons" @click="pauseDelivery(delivery.id)" class="action-btn pause-btn">Pause Delivery</button>
          <button v-if="showPauseResumeButtons" @click="resumeDelivery(delivery.id)" class="action-btn resume-btn">Resume Delivery</button>
          <button @click="cancelDelivery(delivery.id)" class="action-btn cancel-btn">Cancel Delivery</button>
          <button @click="markDeliveryCompleted(delivery.id)" class="action-btn complete-btn">Mark Delivery Completed</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnTheWayOrders',
  props: {
    onTheWayDeliveries: {
      type: Array,
      required: true
    },
    allOrders: {
      type: Object,
      required: true
    },
    showPauseResumeButtons: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatOrderTime(time) {
      return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    getOrderById(orderId) {
      const order = this.allOrders[orderId];
      if (!order) {
        console.warn(`Order with ID ${orderId} not found in allOrders`);
      }
      return order || {};
    },
    pauseDelivery(deliveryId) {
      this.$emit('pauseDelivery', deliveryId);
    },
    resumeDelivery(deliveryId) {
      this.$emit('resumeDelivery', deliveryId);
    },
    cancelDelivery(deliveryId) {
      this.$emit('cancelDelivery', deliveryId);
    },
    markDeliveryCompleted(deliveryId) {
      this.$emit('markDeliveryCompleted', deliveryId);
    }
  }
}
</script>

<style scoped>
.on-the-way-orders {
  width: 100%;
}

.no-orders {
  text-align: center;
  color: #666;
  font-style: italic;
  padding: 1rem;
}

.delivery {
  margin-bottom: 2rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.order-table th,
.order-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-table th:nth-child(1),
.order-table td:nth-child(1) { width: 20%; }

.order-table th:nth-child(2),
.order-table td:nth-child(2) { width: 20%; }

.order-table th:nth-child(3),
.order-table td:nth-child(3) { width: 40%; }

.order-table th:nth-child(4),
.order-table td:nth-child(4) { width: 20%; }

h4 {
  margin-bottom: 1rem;
}

.delivery-actions {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 1rem;
}

.action-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.pause-btn {
  background-color: #ffc107;
  color: #000;
}

.resume-btn {
  background-color: #28a745;
  color: #fff;
}

.cancel-btn {
  background-color: #dc3545;
  color: #fff;
}

.complete-btn {
  background-color: #fd7e14; /* Orange color */
  color: #fff;
}

.complete-btn:hover {
  background-color: #e36b0a; /* Darker orange on hover */
}

.action-btn:hover {
  opacity: 0.8;
}
</style>
