<template>
  <div class="in-preparation-orders">
    <div v-if="(inPreparationOrders.length === 0) && (!readyForPickupDeliveries || readyForPickupDeliveries.length === 0)" class="no-orders">
      No orders currently being prepared.
    </div>
    <div v-else>
      <!-- In Preparation orders section -->
      <div v-if="inPreparationOrders.length > 0">
        <h3>In Preparation</h3>
        <table class="order-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Order Placed At</th>
              <th>Customer</th>
              <th>Items</th>
              <th>Destination</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in inPreparationOrders" :key="order.orderId" class="order-item">
              <td><input type="checkbox" v-model="selectedOrders" :value="order.orderId"></td>
              <td>{{ formatOrderTime(order.placedAt) }}</td>
              <td>{{ order.userName }}</td>
              <td>{{ order.items.join(', ') }}</td>
              <td>{{ order.destination }}</td>
            </tr>
          </tbody>
        </table>
        <div class="group-actions">
          <button 
            @click="createNewDelivery" 
            :class="['action-btn', {'active': selectedOrders.length > 0}]" 
            :disabled="selectedOrders.length === 0"
          >
            Create New Delivery Batch
          </button>
          <select v-model="selectedDeliveryIndex" :disabled="selectedOrders.length === 0 || !readyForPickupDeliveries || readyForPickupDeliveries.length === 0">
            <option disabled value="">Add to Selected Batch</option>
            <option v-for="(delivery, index) in readyForPickupDeliveries" :key="index" :value="index">
              Delivery {{ index + 1 }} ({{ delivery.orderReferences.length }} orders)
            </option>
          </select>
          <button 
            @click="addToExistingDelivery" 
            :class="['action-btn', {'active': selectedOrders.length > 0 && selectedDeliveryIndex !== ''}]" 
            :disabled="selectedOrders.length === 0 || selectedDeliveryIndex === ''"
          >
            Add to Selected Batch
          </button>
        </div>
      </div>

      <!-- Ready for pickup deliveries section -->
      <div v-if="readyForPickupDeliveries && readyForPickupDeliveries.length > 0">
        <h3>Ready for Pickup</h3>
        <div v-for="(delivery, index) in readyForPickupDeliveries" :key="delivery.id" class="delivery">
          <h4>Delivery {{ index + 1 }} ({{ delivery.id }})</h4>
          <table class="order-table">
            <thead>
              <tr>
                <th>Order Placed At</th>
                <th>Customer</th>
                <th>Items</th>
                <th>Destination</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="orderRef in delivery.orderReferences" :key="orderRef.orderId" class="order-item">
                <td>{{ formatOrderTime(getOrderById(orderRef.orderId).placedAt) }}</td>
                <td>{{ getOrderById(orderRef.orderId).userName }}</td>
                <td>{{ getOrderById(orderRef.orderId).items.join(', ') }}</td>
                <td>{{ orderRef.destination }}</td>
                <td>
                  <button @click="removeFromDelivery(delivery.id, orderRef.orderId)" class="remove-btn">
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="dispatch-controls">
            <!-- <select v-model="selectedRobots[delivery.id]" class="robot-select">
              <option value="">Select a robot</option>
              <option v-for="robot in availableRobots" :key="robot.id" :value="robot.id">
                {{ robot.name }}
              </option>
            </select> -->
            <button 
              @click="dispatchDelivery(delivery.id)"
              class="action-btn"
              :disabled="!availableRobots.length"
            >
              Dispatch Delivery
            </button>
            <button 
              @click="dispatchDeliveryManually(delivery.id)"
              class="action-btn dispatch-manually-btn"
            >
              Dispatch Delivery Manually
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InPreparationOrders',
  props: {
    inPreparationOrders: {
      type: Array,
      required: true
    },
    readyForPickupDeliveries: {
      type: Array,
      default: () => []
    },
    allOrders: {
      type: Object,
      required: true
    },
    availableRobots: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedOrders: [],
      selectedDeliveryIndex: '',
      selectedRobots: {}
    };
  },
  watch: {
    availableRobots: {
      handler(newRobots) {
        // Remove any selected robots that are no longer available
        for (const groupId in this.selectedRobots) {
          if (this.selectedRobots[groupId] && !newRobots.some(robot => robot.id === this.selectedRobots[groupId])) {
            this.selectedRobots[groupId] = '';
          }
        }
      },
      deep: true
    }
  },
  methods: {
    formatOrderTime(time) {
      return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    createNewDelivery() {
      if (this.selectedOrders.length > 0) {
        // const selectedOrdersData = this.selectedOrders.map(orderId => this.getOrderById(orderId));
        // const destinations = new Set(selectedOrdersData.map(order => order.destination));
        
        // if (destinations.size > 1) {
        //   alert("Cannot group orders with different destinations.");
        //   return;
        // }
        
        this.$emit('createDelivery', this.selectedOrders);
        this.selectedOrders = [];
      }
    },
    addToExistingDelivery() {
      if (this.selectedOrders.length > 0 && this.selectedDeliveryIndex !== '') {
        const deliveryId = this.readyForPickupDeliveries[this.selectedDeliveryIndex].id;
        this.$emit('addOrdersToDelivery', this.selectedOrders, deliveryId);
        this.selectedOrders = [];
        this.selectedDeliveryIndex = '';
      }
    },
    removeFromDelivery(deliveryId, orderId) {
      this.$emit('removeOrderFromDelivery', deliveryId, orderId);
    },
    dispatchDelivery(deliveryId) {
      if (this.availableRobots.length > 0) {
        const selectedRobotId = this.availableRobots[0].id;
        this.$emit('dispatchDelivery', deliveryId, selectedRobotId);
      }
    },
    getOrderById(orderId) {
      const order = this.allOrders[orderId];
      if (!order) {
        console.warn(`Order with ID ${orderId} not found in allOrders`);
      }
      return order || {};
    },
    dispatchDeliveryManually(deliveryId) {
      this.$emit('dispatchDeliveryManually', deliveryId);
    }
  }
}
</script>

<style scoped>
.in-preparation-orders {
  width: 100%;
}

.no-orders {
  text-align: center;
  color: #666;
  font-style: italic;
  padding: 1rem;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.order-table th,
.order-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-table th:nth-child(1),
.order-table td:nth-child(1) { width: 15%; }

.order-table th:nth-child(2),
.order-table td:nth-child(2) { width: 20%; }

.order-table th:nth-child(3),
.order-table td:nth-child(3) { width: 30%; }

.order-table th:nth-child(4),
.order-table td:nth-child(4) { width: 20%; }

.order-table th:nth-child(5),
.order-table td:nth-child(5) { width: 15%; }

.group-actions {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem;
}

.group-actions > * {
  flex: 1;
  margin: 0 0.5rem;
}

select,
.action-btn {
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.action-btn {
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
  background-color: #6c757d;
}

.action-btn.active {
  background-color: #007bff;
}

.action-btn.active:hover:not(:disabled) {
  background-color: #0056b3;
}

.action-btn:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

.delivery {
  margin-bottom: 2rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
}

.remove-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-btn:hover {
  background-color: #c82333;
}

h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

h4 {
  margin-bottom: 1rem;
}

.dispatch-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

.robot-select {
  margin-right: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.action-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
}

.dispatch-controls .action-btn {
  background-color: #6c757d; /* Default gray color */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dispatch-controls .action-btn:not(:disabled) {
  background-color: #007bff; /* Blue color when not disabled */
}

.dispatch-controls .action-btn:not(:disabled):hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.dispatch-controls .action-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
}

.dispatch-manually-btn {
  background-color: #fd7e14 !important; /* Orange color */
  margin-left: 10px;
}

.dispatch-manually-btn:hover {
  background-color: #e36b0a !important; /* Darker orange on hover */
}
</style>
