<template>
    <div class="completed-orders">
        <div v-if="orders.length === 0" class="no-orders">
            No completed orders.
        </div>
        <table v-else class="order-table">
            <thead>
                <tr>
                    <th>Order Placed At</th>
                    <th>Completed At</th>
                    <th>Customer</th>
                    <th>Items</th>
                    <th>Destination</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="order in sortedOrders" :key="order.orderId" class="order-item">
                    <td>{{ formatOrderTime(order.placedAt) }}</td>
                    <td>{{ formatOrderTime(order.completedAt) }}</td>
                    <td>{{ order.userName }}</td>
                    <td>{{ order.items.join(', ') }}</td>
                    <td>{{ order.destination }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'CompletedOrders',
    props: {
        orders: {
            type: Array,
            required: true
        }
    },
    computed: {
        sortedOrders() {
            return [...this.orders].sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt));
        }
    },
    methods: {
        formatOrderTime(time) {
            return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }
    }
}
</script>

<style scoped>
.completed-orders {
    width: 100%;
}

.no-orders {
    text-align: center;
    color: #666;
    font-style: italic;
    padding: 1rem;
}

.order-table {
    width: 100%;
    border-collapse: collapse;
}

.order-table th,
.order-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}
</style>
