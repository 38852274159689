<template>
    <div class="bartender">
        <div class="toggle-container">
            <span>Accepting Orders: {{ isAcceptingOrders ? 'Yes' : 'No' }}</span>
            <label class="switch">
                <input type="checkbox" v-model="isAcceptingOrders" @change="toggleAcceptingOrders">
                <span class="slider round"></span>
            </label>
        </div>

        <div v-if="alertMessage" class="alert" :class="{ 'alert-error': isErrorAlert }">{{ alertMessage }}</div>

        <!-- Queued Orders Section -->
        <section class="order-section">
            <h2>Queued Orders</h2>
            <div class="order-box scrollable">
                <QueuedOrders :orders="orders.QUEUED" @startPreparing="startPreparing" @cancelOrder="cancelOrder" />
            </div>
        </section>

        <!-- In Preparation & Ready for Pickup Orders Section -->
        <section class="order-section">
            <h2>In Preparation</h2>
            <div class="order-box scrollable">
                <InPreparationOrders :allOrders="allOrders" :inPreparationOrders="inPreparationOrders"
                    :readyForPickupDeliveries="readyForPickupDeliveries" :availableRobots="availableRobots"
                    @createDelivery="createDelivery" @addOrdersToDelivery="addOrdersToDelivery"
                    @removeOrderFromDelivery="removeOrderFromDelivery" @dispatchDelivery="dispatchDelivery"
                    @dispatchDeliveryManually="dispatchDeliveryManually" />
            </div>
        </section>

        <!-- On The Way Orders Section -->
        <section class="order-section">
            <h2>On The Way</h2>
            <div class="order-box scrollable">
                <OnTheWayOrders :allOrders="allOrders" :onTheWayDeliveries="onTheWayDeliveries"
                    @cancelDelivery="cancelDelivery" @markDeliveryCompleted="markDeliveryCompleted" />
            </div>
        </section>

        <!-- Completed Orders Section -->
        <section class="order-section">
            <h2>Completed Orders</h2>
            <div class="order-box scrollable">
                <CompletedOrders :orders="completedOrders" />
            </div>
        </section>
    </div>
</template>

<script>
import { io } from 'socket.io-client';
import { ORDER_STATUS } from '../../constants/order-statuses';
import QueuedOrders from './QueuedOrders.vue';
import InPreparationOrders from './InPreparationOrders.vue';
import OnTheWayOrders from './OnTheWayOrders.vue';
import CompletedOrders from './CompletedOrders.vue';

export default {
    name: 'BartenderView',
    components: {
        QueuedOrders,
        InPreparationOrders,
        OnTheWayOrders,
        CompletedOrders,
    },
    data() {
        return {
            orders: {
                QUEUED: [],
                IN_PREPARATION: [],
                READY_FOR_PICKUP: [],
                ON_THE_WAY: [],
                COMPLETED: [],
                CANCELLED: []
            },
            deliveries: {},
            availableRobots: [],
            alertMessage: '',
            isErrorAlert: false,
            isAcceptingOrders: true,
        };
    },
    computed: {
        inPreparationOrders() {
            return this.orders.IN_PREPARATION || [];
        },
        readyForPickupDeliveries() {
            return Object.values(this.deliveries).filter(delivery => delivery.status === ORDER_STATUS.READY_FOR_PICKUP);
        },
        onTheWayDeliveries() {
            return Object.values(this.deliveries).filter(delivery => delivery.status === ORDER_STATUS.ON_THE_WAY);
        },
        completedOrders() {
            return this.orders.COMPLETED || [];
        },
        allOrders() {
            // Combine all orders into a single object for easy lookup
            const allOrders = {};
            Object.entries(this.orders).forEach(([status, orderArray]) => {
                orderArray.forEach(order => {
                    allOrders[order.orderId] = { ...order, status };
                });
            });
            // Add this log to check the content of allOrders
            console.log('All orders:', allOrders);
            return allOrders;
        }
    },
    created() {
        this.fetchInitialData();
        this.fetchSettings();
    },

    mounted() {
        this.socket = io();
        this.setupSocketListeners();
    },
    beforeUnmount() {
        if (this.socket) {
            this.socket.disconnect();
        }
    },
    methods: {
        async fetchInitialData() {
            try {
                const [orders, deliveries, robots] = await Promise.all([
                    this.fetchOrders(),
                    this.fetchDeliveries(),
                    this.fetchAvailableRobots()
                ]);
                console.log('Initial data fetched:', { orders, deliveries, robots });
            } catch (error) {
                console.error('Error fetching initial data:', error);
                this.showAlert('Failed to fetch initial data', true);
            }
        },
        async fetchOrders() {
            try {
                const response = await fetch('/api/orders');
                const data = await response.json();
                if (response.ok) {
                    this.orders = data;
                    return data; // Return the data
                } else {
                    console.error('Failed to fetch orders:', data.message);
                    this.showAlert('Failed to fetch orders', true);
                    return null; // Return null or an empty object if failed
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
                this.showAlert('Error fetching orders', true);
                return null; // Return null or an empty object if error
            }
        },
        async fetchDeliveries() {
            try {
                const response = await fetch('/api/deliveries');
                const data = await response.json();
                if (response.ok && data.success) {
                    this.deliveries = data.data;
                    return data.data; // Return the data
                } else {
                    console.error('Failed to fetch deliveries:', data.message);
                    this.showAlert('Failed to fetch deliveries', true);
                    return null; // Return null or an empty object if failed
                }
            } catch (error) {
                console.error('Error fetching deliveries:', error);
                this.showAlert('Error fetching deliveries', true);
                return null; // Return null or an empty object if error
            }
        },
        async fetchAvailableRobots() {
            try {
                const response = await fetch('/api/deliveries/available-robots');
                const data = await response.json();
                if (response.ok && data.success) {
                    this.availableRobots = data.data.map(robot => ({
                        id: robot.id,
                        name: robot.name
                    }));
                    return data.data; // Return the data
                } else {
                    console.error('Failed to fetch available robots:', data.message);
                    this.showAlert('Failed to fetch available robots', true);
                    return null; // Return null or an empty object if failed
                }
            } catch (error) {
                console.error('Error fetching available robots:', error);
                this.showAlert('Error fetching available robots', true);
                return null; // Return null or an empty object if error
            }
        },
        setupSocketListeners() {
            this.socket.on('orderUpdated', (data) => {
                console.log('Received orderUpdated event:', data);
                if (data.order) {
                    const { order, oldStatus, newStatus } = data;
                    // console.log(`Updating order ${order.orderId} from ${oldStatus} to ${newStatus}`);

                    // Remove the order from the old status
                    if (oldStatus && this.orders[oldStatus]) {
                        this.orders[oldStatus] = this.orders[oldStatus].filter(o => o.orderId !== order.orderId);
                    }

                    // Add the order to the new status
                    if (newStatus && this.orders[newStatus]) {
                        this.orders[newStatus].push(order);
                    }
                } else if (data.orders) {
                    console.log('Replacing orders with new data');
                    this.orders = data.orders;
                }

                // Force Vue to detect the change
                this.orders = { ...this.orders };
            });

            this.socket.on('deliveryUpdated', (data) => {
                console.log('Received deliveryUpdated event:', data);

                if (data.type === 'deleted' && data.deliveryId) {
                    // Remove the delivery from the local object
                    this.deliveries = Object.fromEntries(
                        Object.entries(this.deliveries).filter(([id]) => id !== data.deliveryId)
                    );
                } else if (data.type === 'error') {
                    console.error('Delivery update error:', data.error);
                    this.showAlert(`Delivery update error: ${data.error}`, true);
                    this.deliveries = {
                        ...this.deliveries,
                        [data.delivery.id]: data.delivery
                    };
                } else if (data.type === 'ordersCompleted') {
                    // Handle completed orders
                    if (data.delivery) {
                        this.deliveries = {
                            ...this.deliveries,
                            [data.delivery.id]: data.delivery
                        };
                        // If the delivery is now empty (all orders completed), remove it
                        if (data.delivery.orderReferences.length === 0) {
                            delete this.deliveries[data.delivery.id];
                        }
                    }
                } else if (data.delivery) {
                    // Update or add the delivery in the local object
                    this.deliveries = {
                        ...this.deliveries,
                        [data.delivery.id]: data.delivery
                    };
                } else {
                    console.error('Invalid delivery update data received:', data);
                }

                // Force Vue to detect the change and update the computed properties
                this.deliveries = { ...this.deliveries };
            });

            this.socket.on('robotStatusUpdated', (data) => {
                console.log('Received robotStatusUpdated event:', data);
                if (data.isAvailable) {
                    // Add the robot to availableRobots if it's not already there
                    if (!this.availableRobots.some(robot => robot.id === data.id)) {
                        this.availableRobots.push({ id: data.id, name: data.name });
                    }
                } else {
                    // Remove the robot from availableRobots
                    this.availableRobots = this.availableRobots.filter(robot => robot.id !== data.id);
                }
            });

            this.socket.on('settingsUpdated', (data) => {
                console.log('Received settingsUpdated event:', data);
                this.isAcceptingOrders = data.isAcceptingOrders;
            });
        },
        async updateOrderStatus(orderId, newStatus) {
            try {
                const response = await fetch(`/api/orders/${orderId}/status`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ status: newStatus })
                });
                const data = await response.json();

                if (response.ok) {
                    return true;
                } else {
                    this.showAlert(`Failed to update order status: ${data.error || 'Unknown error'}`, true);
                    return false;
                }
            } catch (error) {
                console.error(`Error updating order status to ${newStatus}:`, error);
                this.showAlert(`Error updating order status to ${newStatus}`, true);
                return false;
            }
        },
        async startPreparing(orderId) {
            // const result = await this.updateOrderStatus(orderId, ORDER_STATUS.IN_PREPARATION);
            // return result;
            try {
                const existingDelivery = Object.values(this.deliveries).find(delivery =>
                    delivery.status === ORDER_STATUS.READY_FOR_PICKUP
                );

                if (existingDelivery) {
                    // Add to existing delivery
                    await this.addOrdersToDelivery([orderId], existingDelivery.id);
                } else {
                    // Create new delivery
                    await this.createDelivery([orderId]);
                }
            } catch (error) {
                console.error('Error in startPreparing:', error);
                this.showAlert(`Error preparing order: ${error.message}`, true);
            }


        },
        async cancelOrder(orderId) {
            return this.updateOrderStatus(orderId, ORDER_STATUS.CANCELLED);
        },
        getOrderReferenceFromId(orderId) {
            const order = this.allOrders[orderId];

            if (!order) {
                console.error(`Order with ID ${orderId} not found in IN_PREPARATION or READY_FOR_PICKUP status`);
                return null;
            }

            return {
                orderId: order.orderId,
                destination: order.destination
            };
        },
        async createDelivery(selectedOrderIds) {
            console.log('Creating delivery for order IDs:', selectedOrderIds);
            try {
                const orderReferences = selectedOrderIds
                    .map(this.getOrderReferenceFromId)
                    .filter(ref => ref !== null);

                if (orderReferences.length === 0) {
                    throw new Error('No valid order references found');
                }

                const response = await fetch('/api/deliveries', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ orderReferences })
                });
                const data = await response.json();
                if (data.success) {
                    // console.log('Delivery created successfully');
                } else {
                    console.error('Failed to create delivery:', data.message);
                }
            } catch (error) {
                console.error('Error creating delivery:', error);
                this.showAlert(`Error creating delivery: ${error.message}`, true);
            }
        },
        async addOrdersToDelivery(selectedOrderIds, deliveryId) {
            // console.log('Adding orders to delivery:', selectedOrderIds, deliveryId);
            try {
                const orderReferences = selectedOrderIds
                    .map(this.getOrderReferenceFromId)
                    .filter(ref => ref !== null);

                if (orderReferences.length === 0) {
                    throw new Error('No valid order references found');
                }

                const response = await fetch(`/api/deliveries/${deliveryId}/order-references`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ orderReferences })
                });
                const data = await response.json();
                if (data.success) {
                    // console.log('Orders added to delivery successfully');
                } else {
                    console.error('Failed to add orders to delivery:', data.message);
                }
            } catch (error) {
                console.error('Error adding orders to delivery:', error);
                this.showAlert(`Error adding orders to delivery: ${error.message}`, true);
            }
        },
        async removeOrderFromDelivery(deliveryId, orderId) {
            // console.log('Removing order from delivery:', deliveryId, orderId);
            try {
                const orderReference = this.getOrderReferenceFromId(orderId);
                if (!orderReference) {
                    throw new Error(`Invalid order ID: ${orderId}`);
                }

                const response = await fetch(`/api/deliveries/${deliveryId}/order-references`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ orderIds: [orderId] })
                });
                const data = await response.json();
                if (data.success) {
                    // console.log('Order removed from delivery successfully');
                } else {
                    console.error('Failed to remove order from delivery:', data.message);
                }
            } catch (error) {
                console.error('Error removing order from delivery:', error);
                this.showAlert(`Error removing order from delivery: ${error.message}`, true);
            }
        },
        async dispatchDelivery(deliveryId, robotId) {
            // console.log("Dispatching delivery ID:", deliveryId, "with robot ID:", robotId);
            try {
                const response = await fetch(`/api/deliveries/${deliveryId}/dispatch`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ robotId })
                });
                const data = await response.json();
                if (data.success) {
                    // console.log('Delivery dispatched successfully');
                } else {
                    //console.error('Failed to dispatch delivery:', data.message);
                }
            } catch (error) {
                console.error('Error dispatching delivery:', error);
            }
        },
        async cancelDelivery(deliveryId) {
            // console.log('Cancelling delivery:', deliveryId);
            try {
                const response = await fetch(`/api/deliveries/${deliveryId}/cancel`, {
                    method: 'PUT'
                });
                const data = await response.json();
                if (data.success) {
                    // console.log('Delivery cancelled successfully');
                } else {
                    console.error('Failed to cancel delivery:', data.message);
                }
            } catch (error) {
                console.error('Error cancelling delivery:', error);
            }
        },
        async dispatchDeliveryManually(deliveryId) {
            try {
                const response = await fetch(`/api/deliveries/${deliveryId}/dispatch-manually`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                if (data.success) {
                   // this.showAlert('Delivery dispatched manually successfully');
                } else {
                    this.showAlert(`Failed to dispatch delivery manually: ${data.message}`, true);
                }
            } catch (error) {
                console.error('Error dispatching delivery manually:', error);
                this.showAlert('Error dispatching delivery manually', true);
            }
        },
        async markDeliveryCompleted(deliveryId) {
            try {
                const response = await fetch(`/api/deliveries/${deliveryId}/complete`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                if (data.success) {
                    // this.showAlert('Delivery marked as completed successfully');
                } else {
                    this.showAlert(`Failed to mark delivery as completed: ${data.message}`, true);
                }
            } catch (error) {
                console.error('Error marking delivery as completed:', error);
                this.showAlert('Error marking delivery as completed', true);
            }
        },
        async fetchSettings() {
            try {
                const response = await fetch('/api/settings');
                const data = await response.json();
                if (response.ok && data.success) {
                    this.isAcceptingOrders = data.isAcceptingOrders;
                } else {
                    console.error('Failed to fetch settings:', data.message);
                    this.showAlert('Failed to fetch settings', true);
                }
            } catch (error) {
                console.error('Error fetching settings:', error);
                this.showAlert('Error fetching settings', true);
            }
        },
        async toggleAcceptingOrders() {
            try {
                const response = await fetch('/api/settings/accepting-orders', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ isAcceptingOrders: this.isAcceptingOrders })
                });
                const data = await response.json();
                if (data.success) {
                    // this.showAlert(`Globar is now ${this.isAcceptingOrders ? 'accepting' : 'not accepting'} orders`);
                } else {
                    this.showAlert('Failed to update order acceptance status', true);
                    this.isAcceptingOrders = !this.isAcceptingOrders; // Revert the toggle
                }
            } catch (error) {
                console.error('Error updating order acceptance status:', error);
                this.showAlert('Error updating order acceptance status', true);
                this.isAcceptingOrders = !this.isAcceptingOrders; // Revert the toggle
            }
        },
        showAlert(message, isError = false) {
            this.alertMessage = message;
            this.isErrorAlert = isError;
            setTimeout(() => {
                this.alertMessage = '';
                this.isErrorAlert = false;
            }, 10000);
        }
    },
};
</script>

<style scoped>
.bartender {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
}

.header-container h1 {
    margin: 0;
    font-size: 1.5em;
}

.toggle-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px 0;
}

.order-section {
    text-align: left;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
}

.order-box {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    max-height: 450px;
    overflow-y: auto;
}

.scrollable {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.scrollable::-webkit-scrollbar {
    width: 8px;
}

.scrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.alert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    z-index: 1000;
}

.alert-error {
    background-color: #f44336;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #C0D639;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>