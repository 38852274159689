<template>
  <div class="queued-orders">
    <div v-if="orders.length === 0" class="no-orders">
      No orders in the queue.
    </div>
    <table v-else class="order-table">
      <thead>
        <tr>
          <th>Order Placed At</th>
          <th>Customer</th>
          <th>Items</th>
          <th>Destination</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.orderId" class="order-item">
          <td>{{ formatOrderTime(order.placedAt) }}</td>
          <td>{{ order.userName }}</td>
          <td>{{ order.items.join(', ') }}</td>
          <td>{{ order.destination }}</td>
          <td>
            <button @click="startPreparing(order)" class="action-btn start-preparing-btn">
              Start Preparing
            </button>
            <button @click="cancelOrder(order)" class="action-btn cancel-order-btn">
              Cancel Order
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'QueuedOrders',
  props: {
    orders: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatOrderTime(time) {
      return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    startPreparing(order) {
      this.$emit('startPreparing', order.orderId);
    },
    cancelOrder(order) {
      this.$emit('cancelOrder', order.orderId);
    }
  }
}
</script>

<style scoped>
.queued-orders {
  /* Remove padding and background color */
}

.no-orders {
  text-align: center;
  color: #666;
  font-style: italic;
  padding: 1rem;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
}

.order-table th,
.order-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.order-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.order-table tr:hover {
  background-color: #f8f8f8;
}

.action-btn {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white;
  font-weight: bold;
}

.start-preparing-btn {
  background-color: #007bff;
}

.start-preparing-btn:hover {
  background-color: #0056b3;
}

.cancel-order-btn {
  background-color: #dc3545;
  margin-left: 0.5rem;
}

.cancel-order-btn:hover {
  background-color: #c82333;
}
</style>
