<template>
    <div>
        <div v-for="order in orders" :key="order.orderId" class="order-status-box" :class="{ 'past-order': isPastOrders }">
            <div class="order-header">
                <span class="order-item">{{ order.items[0] }}</span>
                <span class="order-time">{{ formatTime(order.placedAt) }}</span>
            </div>
            <div class="order-destination">
                {{ isPastOrders ? 'Delivered to' : 'Your order will be delivered to' }} {{ order.destination }}
            </div>
            <div class="status-container">
                <span :class="['status-badge', getStatusClass(order.status)]">{{ getStatusText(order.status) }}</span>
                <button v-if="!isPastOrders && order.status === ORDER_STATUS.QUEUED" class="cancel-btn" @click="$emit('cancel', order.orderId)">✕</button>
            </div>
            <div v-if="!isPastOrders && order.status === ORDER_STATUS.ON_THE_WAY" class="progress-bar-container">
                <div class="progress-bar">
                    <div class="progress" :style="{ width: (order.progress) + '%' }"></div>
                </div>
                <span class="progress-percent">{{ order.progress }}%</span>
            </div>
        </div>
    </div>
</template>

<script>
import { ORDER_STATUS } from '../../constants/order-statuses';

export default {
    props: {
        orders: {
            type: Array,
            required: true
        },
        isPastOrders: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ORDER_STATUS
        }
    },
    methods: {
        formatTime(time) {
            return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        },
        getStatusClass(status) {
            return {
                'status-queued': status === ORDER_STATUS.QUEUED,
                'status-in-preparation': status === ORDER_STATUS.IN_PREPARATION,
                'status-ready': status === ORDER_STATUS.READY_FOR_PICKUP,
                'status-on-way': status === ORDER_STATUS.ON_THE_WAY,
                'status-completed': status === ORDER_STATUS.COMPLETED,
                'status-cancelled': status === ORDER_STATUS.CANCELLED
            };
        },
        getStatusText(status) {
            switch(status) {
                case ORDER_STATUS.QUEUED: return 'Order confirmed';
                case ORDER_STATUS.IN_PREPARATION: return 'Preparing';
                case ORDER_STATUS.READY_FOR_PICKUP: return 'Ready for delivery';
                case ORDER_STATUS.ON_THE_WAY: return 'On the way';
                case ORDER_STATUS.COMPLETED: return 'Delivered';
                case ORDER_STATUS.CANCELLED: return 'Cancelled';
                default: return 'Unknown';
            }
        }
    }
}
</script>

<style scoped>
.order-status-box {
    background-color: #ffffff;
    border: 2px solid #f0eaea; /* Match menu item border color */
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
}

.order-item {
    color: #000000; /* Black font for order items */
}

.order-time {
    color: #666;
}

.status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.9em;
    font-weight: 500;
}

.status-queued {
    background-color: #007bff; /* Blue for queued */
    color: white;
}

.status-in-preparation {
    background-color: #ffc107; /* Yellow for in preparation */
    color: black;
}

.status-ready {
    background-color: #28a745; /* Green for ready for pickup */
    color: white;
}

.status-on-way {
    background-color: #17a2b8; /* Light blue for on the way */
    color: white;
}

.status-completed {
    background-color: #6c757d;
    color: white;
}

.status-cancelled {
    background-color: #dc3545; /* Red for cancelled */
    color: white;
}

.cancel-btn {
    background-color: transparent;
    border: none;
    color: #dc3545;
    font-size: 1.2em;
    cursor: pointer;
}

.progress-bar-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
}

.progress-bar {
    flex-grow: 1;
    height: 8px;
    background-color: #e9ecef;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
}

.progress {
    height: 100%;
    background-color: #17a2b8;
    border-radius: 4px;
    transition: width 0.5s ease-in-out;
}

.progress-percent {
    font-size: 0.9em;
    color: #666;
}

.order-destination {
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
    color: #666;
    font-size: 0.9em;
}

.past-order {
    background-color: #ffffff;
    border-color: #e9ecef;
}

.order-status-wrapper {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}

.scroll-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to top, rgba(249, 249, 249, 1), rgba(249, 249, 249, 0));
    pointer-events: none;
}
</style>
