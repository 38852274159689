const ORDER_STATUS = {
    QUEUED: 'QUEUED',
    IN_PREPARATION: 'IN_PREPARATION',
    READY_FOR_PICKUP: 'READY_FOR_PICKUP',
    ON_THE_WAY: 'ON_THE_WAY',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED'
};

const ORDER_TYPE = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED'
};

module.exports = { ORDER_STATUS, ORDER_TYPE };